import React from "react"

import sessoesLoja from "../json/modulos/loja.json"

import Preco from "../components/Preco"
import Sessao from "../components/Sessao"

export default function Loja() {

    return (
        <div className="flex justify-center items-center flex-col">
            <div className="text-center pt-12 text-lg p-5">
                <div className="my-12 mb-24 font-light">
                    <h1 className="text-4xl">Agulhão para Lojas</h1>
                    <p className="mt-2">
                        Focado em ser um sistema prático e completo, tudo feito para sua
                        loja <span className="text-primary">crescer e voar</span>
                    </p>
                </div>
                <div className="mb-10">
                    <img className="w-auto" src="assets/agulhao/screenshots/sistema.png" alt="imagem do sistema" />
                </div>
            </div>
            <div className="max-w-7xl px-9">
                {
                    sessoesLoja.map((dados, i) => (
                        <Sessao key={i} {...dados} layout={i % 2} />
                    ))
                }
            </div>
            
            <Preco />
        </div>
    )
}