import React from "react"

import { BsInstagram, BsWhatsapp, BsPinMapFill } from "react-icons/bs"
import { TbMessageDots } from "react-icons/tb"

export function Cabecalho() {
    return (
        <header className="flex flex-col text-white bg-primary">
            <div className="flex items-center justify-center bg-dark">
                <div className="flex justify-between items-center p-1 text-sm max-w-7xl w-full">
                    <div className="flex">
                        <a className="cabecalho-a" target="_blank" href="http://bit.ly/3HoN5U6" rel="noreferrer">(44) 3199-9000</a>
                        <a className="cabecalho-a" href="mailto:contato@agulhao.com.br">contato@agulhao.com.br</a>
                    </div>

                    <div className="flex text-xl py-2">
                        <a className="cabecalho-a pr-2" href="https://www.instagram.com/agulhaosistema/" target="_blank" rel="noreferrer">
                            <BsInstagram />
                        </a>
                        <a className="cabecalho-a pr-2" href="http://bit.ly/3HoN5U6" target="_blank" rel="noreferrer">
                            <BsWhatsapp />
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex max-w-7xl self-center justify-center md:justify-start w-full content-between">
                <a href="/" className="flex flex-col text-center items-center md:text-left md:flex-row text-white py-12 px-5">
                    <img className="w-32"  src="assets/agulhao/logo.svg" alt="Logo do sistema Agulhão" />
                    <div className="md:ml-9 pt-9 md:pt-0">
                        <h1 className="text-5xl font-bold">Agulhão</h1>
                        <p className="pt-3 font-light max-w-lg">
                            Sistema especialista em confecção, feito especialmente para lojas, indústrias e facções que trabalham com a moda
                        </p>
                    </div>
                </a>
            </div>
        </header>
    )
}

export function Rodape() {
    return (
        <footer className="bg-primary text-white flex flex-col" >
            <img className="scale-[-1] h-10" src="assets/agulhao/waves.svg" alt="Ondas" />

            <div className="flex flex-col md:justify-center md:items-center md:flex-row">
                <div className="p-6 md:p-12 items-center justify-center flex-col slogan">
                    <div className="flex logos items-center justify-center">
                        <img
                            src="assets/agulhao/logo.svg"
                            alt="Logo do sistema Agulhão"
                            className="w-12 m-3 "
                        />
                        <img
                            src="assets/agulhao/softros.png"
                            alt="Logo da Softros Sistemas de Informática"
                            className="w-20 m-3 brightness-200"
                        />
                    </div>
                    <p className="text-center">Agulhão, tecendo sua gestão</p>
                </div>
                <div className="p-6 md:p-12 text-sm">
                    <h4 className="mb-2 text-base flex items-center"><BsPinMapFill className="text-xl mr-2" /> Endereço</h4>
                    <p>Rua XV de Novembro, N.º 800 - Centro</p>
                    <p>Palotina, Paraná. 85950-000</p>
                    <a className="cabecalho-a pl-0 underline" href="https://www.google.com.br/maps/place/SoftRos+Sistemas+de+Informatica/@-24.2829811,-53.8448727,17z/data=!3m1!4b1!4m5!3m4!1s0x94f36e65535ea031:0xb071d75b67cdafcc!8m2!3d-24.282986!4d-53.842684" target="_BLANK" rel="noreferrer">Google Maps</a>
                </div>
                <div className="p-6 md:p-12 text-sm">
                    <h4 className="mb-2 text-base flex items-center"><TbMessageDots className="text-xl mr-2" /> Contato</h4>
                    <p>(44) 3199-9000</p>
                    <p>
                        <a className="cabecalho-a pl-0 underline" href="https://api.whatsapp.com/send?phone=5544999760287" target="_BLANK" rel="noreferrer">(44) 9 9976-0287</a>
                    </p>
                    <a className="cabecalho-a pl-0 underline" href="mailto:maciel@softros.com.br">maciel@softros.com.br</a>
                </div>
            </div>

            <div className="m-3 text-center text-sm">
                <p>
                    SoftRos Sistemas de Informática, {new Date().getFullYear()} - Empresa
                    especializada em desenvolvimento de sistemas, não vendemos produtos eletrônicos.
                </p>
                <p className="pt-2">
                    <a className="cabecalho-a pl-0 underline" href="https://politicas.agulhao.com.br/?privacidade" target="_BLANK" rel="noreferrer">Política de privacidade</a> & <a className="cabecalho-a pl-0 underline" href="https://politicas.agulhao.com.br/?termo" target="_BLANK" rel="noreferrer">Termo de uso</a>
                </p>
            </div>
        </footer>
    )
}